import { render, staticRenderFns } from "./join-or-login-form.vue?vue&type=template&id=7e6f53f5"
import script from "./join-or-login-form.vue?vue&type=script&lang=js"
export * from "./join-or-login-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfImg: require('/usr/src/app/components/tf/img.vue').default,AuthSso: require('/usr/src/app/components/auth/sso.vue').default})
